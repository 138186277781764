@import "../../../data/styles.css";

/* Overall project container */
.project {
    mix-blend-mode: normal;
    border-radius: 20px;
    opacity: 0.8;
    height: 100%;
    transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

/* Hover effect for the project container */
.project:hover {
    background: #fafafa;
    opacity: 1;
}

/* Links container */
.project a {
    text-decoration: none;
    color: var(--secondary-color);
    transition: color 0.3s ease-in-out;
}

/* Individual link styling */
.project-link {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-top: 10px; /* Add spacing between links */
}

/* Hover effect for each link (independent) */
.project-link:hover {
    color: var(--link-color);
}

/* Link icon styling */
.project-link-icon {
    padding-left: 5px;
    font-size: 13px;
}

/* Link text styling */
.project-link-text {
    padding-left: 20px;
    font-weight: 700;
}

/* Project content container */
.project-container {
    padding: 30px;
}

/* Project logo styling */
.project-logo {
    width: 30px;
}

.project-logo img {
    width: 100%;
}

/* Project title styling */
.project-title {
    font-family: var(--secondary-font);
    color: var(--primary-color);
    font-size: 16px;
    padding-top: 15px;
    margin-bottom: 1em;
    font-weight: 700;
}

/* Project description styling */
.project-description {
    color: var(--secondary-color);
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .project {
        height: 300px;
    }
}
