:root {
	/* ------- colors ------- */
	--primary-color: #27272a;
	--secondary-color: #65656d;
	--tertiary-color: #acacb4;
	--quaternary-color: #e4e4e7;
	--link-color: #14b8a6;
	/* ---------------------- */

	/* ------- fonts ------- */
	--primary-font: "Heebo", sans-serif;
	--secondary-font: "Roboto", sans-serif;
	/* --------------------- */
}
h1 {
    font-weight: bold; /* Ensures bold text */
    font-size: x-large; /* Adjusts size */
    color: #333; /* Sets a dark color */
    text-transform: uppercase; /* Makes the text uppercase */
    margin-bottom: 20px; /* Adds spacing below the heading */
}